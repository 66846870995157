<template>
  <v-card tile :elevation="0" class="crud-box list-settings">
    <h1 class="mx-4">Manage Settings</h1>
    <v-layout wrap class="px-4 pt-4">
      <div style="overflow: visible">
        <v-btn color="success" class="mr-2" @click="onClickNew">
          <v-icon>mdi-plus</v-icon>
          New
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickImport">
          <v-icon>mdi-database-import</v-icon>
          Import
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickExport">
          <v-icon>mdi-database-export</v-icon>
          Export
        </v-btn>
        <v-btn color="error" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDeleteAll">
          <v-icon>mdi-delete</v-icon>
          Delete Items
        </v-btn>
      </div>
      <!---->
      <v-spacer></v-spacer>
      <v-layout wrap style="max-width: calc(100% - 250px);">
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Name"
                      v-model="searchFields.name.value"/>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" :server-items-length="tblPagination.total" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>

        <template v-slot:item.actions="props">
          <v-icon small @click="onClickDetail(props)" class="mr-2">mdi-view-list</v-icon>
          <v-icon small @click="onClickEdit(props)" class="mr-2">mdi-pencil</v-icon>
          <v-icon small @click="onClickDelete(props)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-card tile :elevation="0" class="block-tbl ma-4">
      <h3>Trigger Command</h3>
      <v-text-field dense outlined class="mr-2" label="Command" v-model="triggerCommandData.command_str"/>
      <v-text-field dense outlined class="mr-2" label="Argument" v-model="triggerCommandData.arguments"/>
      <v-btn color="primary" @click="executeCommand">
        <v-icon>mdi-apple-keyboard-command</v-icon>
        Execute
      </v-btn>
      <div class="command-results">
        <div class="command-result" v-for="(commandResult, index) in commandResults" :key="index">
          <div class="command-content">Command: {{ commandResult.command_str }}</div>
          <div class="command-arguments">Argument: {{ commandResult.arguments }}</div>
          <pre class="return-result">Result:
{{ commandResult.result }}
          </pre>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="800">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import indexMixin from './indexMixin';

export default {
  name: 'ManageSettings',
  components: {DetailItem, UpdateItem},
  mixins: [indexMixin],
  data() {
    return {
      triggerCommandData: {
        command_str: '',
        arguments: '',
      },
      commandResults: [],
      searchFields: {
        name: {
          value: '',
          type: 'like',
        },
      },
      searchWith: 'role',
      searchWithCount: '',
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },

        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Value',
          value: 'value',
        },
        {
          text: 'Unit',
          value: 'unit',
        },
        {
          text: 'Role',
          value: 'role.name',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '111px',
          align: 'center',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'setting/all',
      tblPagination: 'setting/pagination'
    }),
  },
  methods: {
    ...mapActions({
      getListItems: 'setting/get',
      exportListItems: 'setting/export',
      triggerCommand: 'setting/triggerCommand',
    }),
    async executeCommand() {
      let res = await this.triggerCommand(this.triggerCommandData);
      this.commandResults.push({
        ...this.triggerCommandData,
        result: atob(res)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.command-results {
  width: 100%;
  height: 400px;
  overflow: auto;

  .command-result {
    margin: 0.5rem;
    padding: 0.5rem;
    outline-color: #ddd;
    outline-style: solid;
    outline-width: thin;
    border-radius: 0.5rem;

    .command-content {
      border-bottom: 1px dashed #ddd;
      padding-bottom: 0.5rem;
    }

    .command-arguments {
      border-bottom: 1px dashed #ddd;
      padding: 0.5rem 0;
    }
  }
}
</style>
